// @flow

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Accordion, Stack } from 'basis';
import NumberFormat from 'react-number-format';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import MainRateWidget, {
  purposeOptions,
  repaymentPeriodOptions,
  isPeriodValueValid,
  getDefaultRepaymentPeriodValue,
  getDefaultLoanAmount,
} from './MainRateWidget';
import {
  LOAN_PAY_FREQUENCY,
} from '../../utils/constants';
import { LOAN_PURPOSE_AU } from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';

type Props = {
  /** Includes default text and footer with cta  */
  main?: boolean,

  /** Displays all in Quote fields */
  quote?: boolean,

  /** Displays all in Result fields */
  result?: boolean,

  /** TODO - define type */
  // getRate:

  /** Broker variant used on the '/brokers/' page */
  broker?: boolean,

  /** TODO - define type */
  stickyQuote?: boolean,

  /** Allows you to preset a value */
  values?: { purpose?: string },

  // isGem?: boolean,

  /** Better start locks purpose to debt con */
  isBetterStart?: boolean
};

function EstimateRateWidget(props: Props) {
  const {
    main,
    quote,
    result,
    getRate,
    stickyQuote,
    broker,
    isGem,
    setWidgetValues,
    showPurposeError,
    showPurposeErrorMessage,
    showPurposeOtherError,
    showPurposeOtherErrorMessage,
    showAmountMinError,
    showAmountMaxError,
    isBetterStart,
  } = props;
  const defaultLoanAmount = getDefaultLoanAmount({isGem, isBroker: broker, isBetterStart});
  const [loanAmount, setLoanAmount] = useState(
    (typeof window !== 'undefined' && sessionStorage.getItem('loanAmount')) ??
    defaultLoanAmount
  );
  const [brokerFee, setBrokerFee] = useState( (typeof window !== 'undefined' && sessionStorage.getItem('brokerFee')) ??
    0 );
  const [purpose, setPurpose] = useState(
    (typeof window !== 'undefined' && sessionStorage.getItem('purpose')) ??
      (isBetterStart ? LOAN_PURPOSE_AU.DEBT_CONSOLIDATION : LOAN_PURPOSE_AU.NOT_SELECTED)
  );
  const [purposeOther, setPurposeOther] = useState(
    (typeof window !== 'undefined' && sessionStorage.getItem('purposeOther')) ??
      ''
  );
  const [frequency, setFrequency] = useState( LOAN_PAY_FREQUENCY.MONTHLY);
  /** When we switch between betterStart and broker/customer, the 'repaymentPeriod' stored in the session may not be valid, so we check first: */
  let defaultRepaymentPeriod = typeof window !== 'undefined' && sessionStorage.getItem('repaymentPeriod');
  if (defaultRepaymentPeriod) {
    if (!isPeriodValueValid({value: defaultRepaymentPeriod, isBetterStart, isGem })) {
      defaultRepaymentPeriod = getDefaultRepaymentPeriodValue({isBetterStart, isGem})
      sessionStorage.setItem('repaymentPeriod',defaultRepaymentPeriod);
    }
  }
  const [ repaymentPeriod, setRepaymentPeriod ] = useState(defaultRepaymentPeriod);
  const StyledPContent = styled.p`
    color: #006aff;
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 20px;
  `;

  const StyledPHeader = styled.p`
    color: #414141;
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 20px;
  `;

  const AmountFrequencyContainer = styled.div`
    @media (max-width: ${BREAKPOINT.SM}) {
      width: 22%;
    }
    @media (min-width: ${BREAKPOINT.SM}) {
      width: 33%;
    }
  `;

  const PurposeContainer = styled.div`
    @media (max-width: ${BREAKPOINT.SM}) {
      width: 56%;
    }
    @media (min-width: ${BREAKPOINT.SM}) {
      width: 33%;
    }
  `;

  const values = {
    loanAmount,
    purpose,
    purposeOther,
    frequency,
    repaymentPeriod,
    brokerFee
  };

  const updateValues = {
    setLoanAmount,
    setPurpose,
    setPurposeOther,
    setFrequency,
    setRepaymentPeriod,
    setBrokerFee,
  };

  useEffect(() => {
    setLoanAmount(
      (typeof window !== 'undefined' && sessionStorage.getItem('loanAmount')) ??
        defaultLoanAmount
    );
    setBrokerFee(
      (typeof window !== 'undefined' && parseInt(sessionStorage.getItem('brokerFee'), 10)) ??
        0
    )
    if (isBetterStart) {
      setPurpose(LOAN_PURPOSE_AU.DEBT_CONSOLIDATION);
    } else {
      setPurpose(
        props && props.values && props.values.purpose
          ? props.values.purpose
          : (typeof window !== 'undefined' &&
              sessionStorage.getItem('purpose')) ??
              LOAN_PURPOSE_AU.NOT_SELECTED
      );
    }
    setPurposeOther(
      (typeof window !== 'undefined' &&
        sessionStorage.getItem('purposeOther')) ??
        ''
    );
    setFrequency(
      (typeof window !== 'undefined' && sessionStorage.getItem('frequency')) ??
        LOAN_PAY_FREQUENCY.MONTHLY
    );
    setRepaymentPeriod(
      (typeof window !== 'undefined' &&
        sessionStorage.getItem('repaymentPeriod')) ??
        '5'
    );
  }, []);

  useEffect(() => {
    if (setWidgetValues) {
      let widget = {};
      widget = {
        loanAmount,
        frequency,
        repaymentPeriod,
        purpose,
        purposeOther,
        brokerFee
      };

      setWidgetValues(widget);
    }
  }, [loanAmount, frequency, repaymentPeriod, purpose, purposeOther, brokerFee]);

  return (
    <>
      {stickyQuote && (
        <Accordion
          itemGap="small"
          color="secondary.lightBlue.t25"
          textColor="primary.blue.t100"
        >
          <Accordion.Item>
            <Accordion.Item.Header>
              <div style={{ display: 'flex', width: '100%' }}>
                <PurposeContainer>
                  <Stack>
                    <StyledPHeader>Purpose</StyledPHeader>
                    <StyledPContent>
                      <strong>
                        {
                          purposeOptions.filter(
                            (purposeObj: object) => purposeObj.value === purpose
                          )?.[0]?.text
                        }
                      </strong>
                    </StyledPContent>
                  </Stack>
                </PurposeContainer>
                <AmountFrequencyContainer>
                  <Stack>
                    <StyledPHeader>Amount</StyledPHeader>
                    <StyledPContent>
                      <strong>
                        <NumberFormat
                          value={loanAmount}
                          displayType="text"
                          thousandSeparator
                          prefix="$"
                        />
                      </strong>
                    </StyledPContent>
                  </Stack>
                </AmountFrequencyContainer>
                <AmountFrequencyContainer>
                  <Stack>
                    <StyledPHeader>Term</StyledPHeader>
                    <StyledPContent>
                      <strong>
                        {
                          repaymentPeriodOptions().filter(
                            (repaymentPeriodObj: object) =>
                              repaymentPeriodObj.value === repaymentPeriod
                          )?.[0]?.text
                        }
                      </strong>
                    </StyledPContent>
                  </Stack>
                </AmountFrequencyContainer>
              </div>
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <MainRateWidget
                values={values}
                stickyQuote={stickyQuote}
                result={result}
                main={main}
                quote={quote}
                broker={broker}
                isGem={isGem}
                getRate={getRate}
                updateValues={updateValues}
                showPurposeError={showPurposeError}
                showPurposeErrorMessage={showPurposeErrorMessage}
                showPurposeOtherError={showPurposeOtherError}
                showPurposeOtherErrorMessage={showPurposeOtherErrorMessage}
                showAmountMinError={showAmountMinError}
                showAmountMaxError={showAmountMaxError}
                isBetterStart={isBetterStart}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        </Accordion>
      )}
      {!stickyQuote && (
        <MainRateWidget
          values={values}
          stickyQuote={stickyQuote}
          result={result}
          main={main}
          quote={quote}
          broker={broker}
          isGem={isGem}
          getRate={getRate}
          updateValues={updateValues}
          showPurposeError={showPurposeError}
          showPurposeErrorMessage={showPurposeErrorMessage}
          showPurposeOtherError={showPurposeOtherError}
          showPurposeOtherErrorMessage={showPurposeOtherErrorMessage}
          showAmountMinError={showAmountMinError}
          showAmountMaxError={showAmountMaxError}
          isBetterStart={isBetterStart}
        />
      )}
    </>
  );
}

export default EstimateRateWidget;
