// @flow
/* eslint-disable flowtype/require-parameter-type */

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from '@latitude/link';
import SvgInline from '@latitude/svg-inline';
import { Text } from '@latitude/text';
import { Box } from '@latitude/box';
import { Tel } from '@latitude/tel';
import { LOAN_PURPOSE, LOAN_PURPOSE_AU } from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import {
  SOFT_QUOTE_AU_RETRIEVE_URL,
  SOFT_QUOTE_NZ_RETRIEVE_URL,
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  LOAN_PAY_FREQUENCY,
  FONT_FAMILY,
  PL_MINIMUM_AMOUNT,
  PL_BROKER_MINIMUM_AMOUNT,
  PL_GEM_MINIMUM_AMOUNT,
  PL_MAXIMUM_AMOUNT,
  PL_BROKER_MAXIMUM_AMOUNT,
  FONT_WEIGHT,
  PL_TEL,
  FONT_SIZE,
  PL_GEM_MAXIMUM_AMOUNT,
  PL_GEM_TEL,
  PL_BETTER_START_MINIMUM_AMOUNT
} from '@/utils/constants';
import Modal from '../Modal/Modal';
import { getSalesforceBrokerUrl } from '../../utils/getUrlFromEnvUtil';
import { formatAsCurrency } from '../../utils';
import Select from '../Select/Select';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import ConfettiText from '../ConfettiText/ConfettiText';
import Input from '../Input/Input';
import { validateBrokerFee } from '../PersonalLoanSoftQuote/validator/brokerValidator';

type Props = {
  /** TODO - define type */
  // values:,

  /** TODO - define type */
  // updateValues:,

  /** */
  stickyQuote?: boolean,

  /** Displays all in Result fields */
  result?: boolean,

  /** Includes default text and footer with cta  */
  main?: boolean,

  /** Displays all in Quote fields */
  quote?: boolean,

  /** Broker variant used on the '/brokers/' page */
  broker?: boolean,

  isGem?: boolean,

  /** TODO - define type */
  // getRate:`
  /** Better Start loans display different loan terms and lock in the purpose as debt-consolidation */
  isBetterStart: boolean
};

const Container = styled(Box)`
  @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}) {
    width: 100%;

    margin-left: auto;
    margin-right: auto;
  }

  ${props =>
    props.stickyQuote &&
    css`
      margin: 0 -32px 0 -8px;

      @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}) {
        width: auto;
        max-width: none;
        margin: 0 -32px 0 -8px;
        /* max-width: 540px; */
      }
    `};
  height: 100%;
`;

const MainContent = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${COLOR.BLUE_BABY};
  padding: 1em 1.5em 0em;
  height: auto;
  padding: 1.5em;
  padding-bottom: 0.5em;
  border: 1px solid ${COLOR.GREY4};
  border-bottom: 0px;

  ${props =>
    props.stickyQuote &&
    css`
      background-color: #eff7ff;
      padding: 0 0;
      border: none;
    `};
`;

const FormFields = styled(Box)`
  max-width: 300px;
  margin: auto;
  ${props =>
    !props.main &&
    !props.broker &&
    css`
      display: flex;
      flex-direction: column;
    `}
  @media (min-width: ${BREAKPOINT.XL}) {
    ${props =>
      props.main &&
      css`
        max-width: 100%;
        display: flex;
        justify-content: space-between;
      `}
  }

  ${props =>
    props.stickyQuote &&
    css`
      background-color: #eff7ff;
      padding: 0 0;
      border: none;
    `};
`;

const LoanPurpose = styled.div`
  @media (min-width: ${BREAKPOINT.XL}) {
    ${props =>
      props.main &&
      css`
        flex: 0 1 calc(40% - 16px);
      `}
  }
`;

const BorrowAmount = styled.div`
  @media (min-width: ${BREAKPOINT.XL}) {
    flex: 0 1 calc(30% - 8px);
  }
  .currency-input__error-message {
    text-align: left !important;
  }
  ${props =>
    props.stickyQuote &&
    css`
      background-color: #eff7ff;
      padding: 0 0;
      border: none;
    `};
`;

const RepaymentPeriod = styled.div`
  @media (min-width: ${BREAKPOINT.XL}) {
    ${props =>
      props.main &&
      css`
        flex: 0 1 calc(30% - 8px);
      `}
  }
`;

const Title = styled.h5`
  font-size: 20px;
  line-height: 1.2;
  text-align: ${ALIGN.CENTER};
  color: ${COLOR.BLACK};
  margin-top: 0;
  margin-bottom: ${MARGIN.M16};
  font-family: ${FONT_FAMILY.TITLE};
`;

const Footer = styled(Box)`
  background-color: ${COLOR.BLUE_SKY};
  padding: 0.5em 1.5em;
  border: 1px solid ${COLOR.GREY4};
  border-top: 0px;
`;

const ResultInfoContainer = styled.div`
  background-color: #b8f3fb;
  padding: 8px;
  margin-bottom: 16px;
  @media (max-width: ${BREAKPOINT.LG_DOWN}) {
    margin-top: -8px !important;
  }
`;

const StyledText = styled(Text)`
  font-family: ${FONT_FAMILY.TITLE} !important;
  line-height: 22px;
`;

export const purposeOptions = [
  { value: LOAN_PURPOSE_AU.NOT_SELECTED, text: 'Select...' },
  { value: LOAN_PURPOSE_AU.CAR_PURCHASE, text: 'Car purchase' },
  { value: LOAN_PURPOSE_AU.CAR_REPAIRS, text: 'Car repairs' },
  { value: LOAN_PURPOSE_AU.DEBT_CONSOLIDATION, text: 'Debt consolidation' },
  { value: LOAN_PURPOSE_AU.EDUCATIONAL_EXPENSES, text: 'Educational expenses' },
  { value: LOAN_PURPOSE_AU.HOME_IMPROVEMENTS, text: 'Home improvements' },
  { value: LOAN_PURPOSE_AU.HOUSEHOLD_FURNISHINGS, text: 'Household furnishings' },
  { value: LOAN_PURPOSE_AU.MEDICAL_DENTAL, text: 'Medical / Dental' },
  { value: LOAN_PURPOSE_AU.OTHER_VEHICLE_PURCHASE, text: 'Other vehicle purchase' },
  { value: LOAN_PURPOSE_AU.SMALL_DEBTS, text: 'Small debts' },
  { value: LOAN_PURPOSE_AU.TRAVEL, text: 'Travel' },
  { value: LOAN_PURPOSE_AU.OTHER, text: 'Other' }
];

export const purposeNzOptions = [
  { value: 'purpose', text: 'Select...' },
  { value: LOAN_PURPOSE.AUTO_PURCHASE, text: 'Auto Purchase' },
  { value: LOAN_PURPOSE.AUTO_REPAIRS, text: 'Auto Repairs' },
  { value: LOAN_PURPOSE.CARAVAN_BOAT_RECREATIONAL, text: 'Caravan/Boat/Recreational' },
  { value: LOAN_PURPOSE.CONSOLIDATION, text: 'Consolidation' },
  { value: LOAN_PURPOSE.EDUCATION, text: 'Education' },
  { value: LOAN_PURPOSE.HOME_IMPROVEMENTS, text: 'Home improvements' },
  {
    value: LOAN_PURPOSE.HOUSEHOLD_FURNISHINGS,
    text: 'Household furnishings'
  },
  { value: LOAN_PURPOSE.MEDICAL_DENTAL, text: 'Medical & Dental' },
  {
    value: LOAN_PURPOSE.TRAVEL_HOLIDAY,
    text: 'Travel/Holiday'
  },
  { value: LOAN_PURPOSE.OTHER, text: 'Other' }
];

export function getPurposeOptions({ isGem, isBetterStart }) {
  if (isBetterStart) return purposeOptions.filter( oneOption => oneOption.value === LOAN_PURPOSE_AU.DEBT_CONSOLIDATION);
  return isGem ? purposeNzOptions : purposeOptions;
}
export const repaymentPeriodOptions =
  [{ value: '2', text: '2 years', default: true },
  { value: '3', text: '3 years' },
  { value: '4', text: '4 years' },
  { value: '5', text: '5 years' },
  { value: '6', text: '6 years' },
  { value: '7', text: '7 years' }];
  /**
   * The values returned in the QUOTE_LOAN_TERM field from the Sales Force API has a different format to the required display format
   * of the UI Text for  for Better Start (only as of September 2021). Hence we added the values expected back from the
   * sales force api for the better start repayment period options object. When attempting to match values for
   * GEM or non-better start (customer au) loans, we will fall back to the `text` property if the `sfApiValue is undefined.
   */
  export const repaymentPeriodBetterStartOptions =
  [{ value: '1.25', text: '1 year (+3 months)', sfApiValue: '1 year(+3 months)' },
  { value: '2.25', text: '2 years (+3 months)', sfApiValue: '2 Years(+3 Months)' },
  { value: '3.25', text: '3 years (+3 months)', sfApiValue: '3 Years(+3 Months)' },
  { value: '4.25', text: '4 years (+3 months)', sfApiValue: '4 Years(+3 Months)' },
  { value: '5.25', text: '5 years (+3 months)', sfApiValue: '5 Years(+3 Months)', default:true },
  { value: '6.25', text: '6 years (+3 months)', sfApiValue: '6 Years(+3 Months)' },
  { value: '7.25', text: '7 years (+3 months)', sfApiValue: '7 Years(+3 Months)' }];

export const repaymentPeriodNzOptions =
  [{ value: '0.5', text: '6 months' },
    { value: '1', text: '1 year' },
    { value: '1.5', text: '1.5 years' },
    { value: '2', text: '2 years' },
    { value: '2.5', text: '2.5 years' },
    { value: '3', text: '3 years' },
    { value: '3.5', text: '3.5 years' },
    { value: '4', text: '4 years' },
    { value: '4.5', text: '4.5 years' },
    { value: '5', text: '5 years', default: true },
    { value: '5.5', text: '5.5 years' },
    { value: '6', text: '6 years' },
    { value: '6.5', text: '6.5 years' },
    { value: '7', text: '7 years' }];

export function getRepaymentPeriodOptions({ isBetterStart, isGem }) {
  let options = repaymentPeriodOptions;
  if (isBetterStart) options = repaymentPeriodBetterStartOptions;
  if (isGem) options = repaymentPeriodNzOptions;
  return options;
}

export const frequencyOptions = [
  { value: LOAN_PAY_FREQUENCY.WEEKLY, text: 'Week' },
  { value: LOAN_PAY_FREQUENCY.FORTNIGHTLY, text: 'Fortnight' },
  { value: LOAN_PAY_FREQUENCY.MONTHLY, text: 'Month' }
];
export function isPeriodValueValid({ value, isBetterStart, isGem }) {

  return getRepaymentPeriodOptions({ isBetterStart, isGem }).some( oneRepaymentOption => oneRepaymentOption.value === value);
}
export const getDefaultRepaymentPeriodValue = ({ isBetterStart, isGem }) => {
  let options = getRepaymentPeriodOptions({ isBetterStart, isGem });
  const defaultItem = options.find( item => item.default);

  if (defaultItem) return defaultItem.value;
  /* eslint-disable-next-line no-console */
  console.log(`No default repayment period specified for ${ isBetterStart? 'better start': isGem ? 'Gem':'customer'} personal loans`);
  return '';
}


export function getRepaymentPeriodObject({ isBetterStart, isGem, sfApiValue, value }) {
  let repaymentPeriodOptions = getRepaymentPeriodOptions({ isBetterStart, isGem });
  let matchingLoanTermOption;
  if (Array.isArray(repaymentPeriodOptions)) {
    if (value) {
      matchingLoanTermOption = repaymentPeriodOptions.find( oneloanTermOption => value === oneloanTermOption.value);
    } else if (sfApiValue) {
      matchingLoanTermOption = repaymentPeriodOptions.find( oneloanTermOption => sfApiValue === ((oneloanTermOption.sfApiValue === undefined) ? oneloanTermOption.text : oneloanTermOption.sfApiValue));
    }
    if (matchingLoanTermOption) {
      return matchingLoanTermOption;
    }
  }
  return null;
}
const amountLessThanMinMessage = ({plMinAmount, isBetterStart}) => {
  return (
    <Text color={COLOR.TEXT_ERROR} fontWeight={FONT_WEIGHT.NORMAL}>
      Loan amount must be at least {formatAsCurrency(plMinAmount)}{isBetterStart?' for a Better Start Personal Loan':''}
    </Text>
  );
};

const amountMoreThanMaxMessage = (plMaxAmount, isBroker, isGem) => {
  return (
    <Text color={COLOR.TEXT_ERROR} fontWeight={FONT_WEIGHT.NORMAL}>
      {`For loan amounts more than ${formatAsCurrency(plMaxAmount)} `}{' '}
      {isBroker ? (
        <>please contact your Latitude BDM</>
      ) : isGem ? (
        <>
          please call us on <Tel color={COLOR.TEXT_ERROR} no={PL_GEM_TEL} />, Mon -
          Fri, 8:30am - 6:30pm (NZST){' '}
        </>
      ) : (
        <>
          please call us on <Tel color={COLOR.TEXT_ERROR} no={PL_TEL} />, Mon -
          Fri, 8am - 7pm (AEST){' '}
        </>
      )}
    </Text>
  );
};

const getMinLoanAmount = ({ isGem, isBroker, isBetterStart}) => {
  if (isGem) return PL_GEM_MINIMUM_AMOUNT; //Gem does not have better start loan in August 2021
  if (isBetterStart && isBroker) {
    return Math.max(PL_BETTER_START_MINIMUM_AMOUNT,PL_BROKER_MINIMUM_AMOUNT);
  }
  if (isBroker) {
    return PL_BROKER_MINIMUM_AMOUNT;
  }
  if (isBetterStart) {
    return PL_BETTER_START_MINIMUM_AMOUNT;
  }
  return PL_MINIMUM_AMOUNT;
}

export const getDefaultLoanAmount = ({ isGem, isBroker, isBetterStart}) => {
  if (isBroker && isBetterStart) return Math.max( PL_BETTER_START_MINIMUM_AMOUNT, PL_BROKER_MINIMUM_AMOUNT );
  if (isBroker) return PL_BROKER_MINIMUM_AMOUNT;
  if (isGem) return PL_GEM_MINIMUM_AMOUNT;
  if (isBetterStart) return PL_BETTER_START_MINIMUM_AMOUNT;
  return PL_MINIMUM_AMOUNT;
}
export const validateLoanAmount = ({amountValue, isBroker, isGem, isBetterStart}) => {
  let isError;
  let isWarning;
  let showAmountMinError = false;
  let showAmountMaxError = false;
  let message = '';

  // determine minimum loan amount for product
  const plMinAmount = getMinLoanAmount({isGem, isBroker, isBetterStart});
  const plMaxAmount = isGem? PL_GEM_MAXIMUM_AMOUNT : isBroker ? PL_BROKER_MAXIMUM_AMOUNT : PL_MAXIMUM_AMOUNT;

  // validate input amount value
  if (!amountValue || amountValue < plMinAmount) {
    isError = false;
    isWarning = true;
    showAmountMinError = true;
    message = amountLessThanMinMessage({plMinAmount, isBetterStart});
  } else if (amountValue > plMaxAmount) {
    isError = false;
    isWarning = true;
    showAmountMaxError = true;
    message = amountMoreThanMaxMessage(plMaxAmount, isBroker, isGem);
  } else {
    isError = false;
    isWarning = false;
  }

  return {
    isError,
    isWarning,
    showAmountMinError,
    showAmountMaxError,
    message
  };
};
function MainRateWidget(props: Props) {
  const [hasLoanAmountError, setHasLoanAmountError] = useState(false);
  const [hasLoanAmountWarning, setHasLoanAmountWarning] = useState(false);
  const [amountValidationMessage, setAmountValidationMessage] = useState('');

  const [purposeHasChanged, setPurposeHasChanged] = useState(false);
  const [purposeOtherHasChanged, setPurposeOtherHasChanged] = useState(false);
  const [loanPurposeMoreInfoModalOpen, setLoanPurposeMoreInfoModalOpen] = useState(false);

  const showPurposeErrorMessage =
    'Select a loan purpose';
  const showPurposeOtherErrorMessage = 'Enter a loan purpose';
  let showAmountErrorMessage;

  const {
    values,
    updateValues,
    stickyQuote,
    result,
    main,
    quote,
    broker,
    isGem,
    getRate,
    showPurposeError,
    showPurposeOtherError,
    showAmountMinError,
    showAmountMaxError,
    isBetterStart,
  } = props;

  const {
    purpose,
    purposeOther,
    loanAmount,
    frequency,
    repaymentPeriod,
    brokerFee
  } = values;

  const {
    setLoanAmount,
    setPurpose,
    setPurposeOther,
    setFrequency,
    setRepaymentPeriod,
    setBrokerFee,
  } = updateValues;
  const [ brokerFeeHasError, setBrokerFeeHasError ] = useState(false);
  const [ brokerFeeErrorMessage, setBrokerFeeErrorMessage ] = useState('');
  const [ brokerFeeFieldValue, setBrokerFeeFieldValue ] = useState(`${brokerFee}` || '0'); // May not be a valid broker fee amount but stores the state of the input element.
  /** as of August 2021 (LM-2994), the broker fee validity depends on the loan value AND the broker fee.
   * Previoulsy it depended only on the broker fee (Aug 2021 saw a sliding scale of max broker fees depending on loan values)
   * Hence we move this error message generation out of the onChange event for the broker fee and into an effect:
   */
   useEffect( ()=> {
     if (broker && setBrokerFee) {
      const validationResult = validateBrokerFee({
        loanAmount,
        brokerFeeFieldValue,
      });
      if (!validationResult.isError) {
        const newFieldValue = validationResult.brokerFee||'';
        sessionStorage.setItem('brokerFee',`${newFieldValue}`);
        setBrokerFee(validationResult.brokerFee);
      } else {
        setBrokerFee(false);
      }
      setBrokerFeeHasError(validationResult.isError);
      setBrokerFeeErrorMessage(validationResult.message)
    }
  },[brokerFeeFieldValue, loanAmount, brokerFee]);

  if (showAmountMinError) {
    const plMinAmount = getMinLoanAmount({isGem, isBroker:broker, isBetterStart});
    showAmountErrorMessage = amountLessThanMinMessage({plMinAmount, isBetterStart});
  }

  if (showAmountMaxError) {
    const plMaxAmount = isGem ? PL_GEM_MAXIMUM_AMOUNT : broker ? PL_BROKER_MAXIMUM_AMOUNT : PL_MAXIMUM_AMOUNT;
    showAmountErrorMessage = amountMoreThanMaxMessage(plMaxAmount, broker, isGem);
  }
  const aquaTextInfo = broker
    ? "The quoted loan amount and loan term above will be captured in your client's " +
      'application which can be easily updated when you apply.'
    : 'The quoted loan amount and loan term above will be captured in ' +
      'your application which you can easily update when you apply.';
  return (
    <Container stickyQuote={stickyQuote}>
      <MainContent stickyQuote={stickyQuote}>
        {!result && !stickyQuote && (
          <Title>
            {broker && !quote
              ? 'Check your client’s rate'
              : main
              ? 'Check your rate'
              : // default
                'Loan details'}
          </Title>
        )}

        {!quote && !stickyQuote && !result && (
          <Text align={ALIGN.CENTER} marginBottom={MARGIN.M16}>
            {result ? (
              <>
                Use our repayment calculator to work out your repayments. Your{' '}
                <strong>${loanAmount}</strong> loan amount and{' '}
                <strong>{repaymentPeriod} year</strong> loan term will be
                captured when you apply. You can update these details in your
                application.
              </>
            ) : broker ? (
              <>
                We’ll ask you a few quick questions about your client’s
                circumstances and credit history to give you a personalised
                interest rate and repayment amount for a <b>personal loan.</b>
              </>
            ) : isGem ? (
                <>
                  We’ll ask you a few quick questions about your circumstances
                  to give you a personalised interest rate and repayment amount.
                </>
              ) : (
              // default
              <>
                We’ll ask you a few quick questions about your circumstances and
                credit history to give you a personalised interest rate and
                repayment amount.
              </>
            )}
          </Text>
        )}

        {!quote && !stickyQuote && result && (
          <ResultInfoContainer>
            <StyledText
              align={ALIGN.CENTER}
              fontSize={FONT_SIZE.NORMAL}
              fontWeight={FONT_WEIGHT.SEMI_BOLD}
              color={COLOR.BLACK}
            >
              {aquaTextInfo}
            </StyledText>
          </ResultInfoContainer>
        )}

        <FormFields main={main} broker={broker}>
          <LoanPurpose main={main} broker={broker}>
            {!result && (
              <>
                <Box className="mb-4" css="min-height: 63px;">
                  {purpose && (
                    <>
                    <Select
                      id="purpose"
                      widget
                      color={COLOR.BLUE}
                      label={
                        broker
                          ? 'My client needs a loan for'
                          : 'I need a loan for'
                      }
                      selectedValue={purpose}
                      values={getPurposeOptions({isGem, isBetterStart})}
                      disabled={isBetterStart}
                      onChange={e => {
                        sessionStorage.setItem('purpose', e.target.value);
                        setPurpose(e.target.value);
                        setPurposeHasChanged(true);
                      }}
                      showError={
                        (purposeHasChanged && purpose === 'purpose') ||
                        (!purposeHasChanged && showPurposeError)
                      }
                      showErrorMessage={showPurposeErrorMessage}
                    />
                    <Link
                      href='#'
                      noStyle
                      onClick={(e) => {
                        setLoanPurposeMoreInfoModalOpen(true);
                        e.preventDefault();
                      }}
                      trackEventData={{ location: 'Loan details' }}
                      style={{display: (isGem && purpose==='consolidation')?'flex':'none', alignItems: 'center', borderBottomStyle: 'none' }}
                    >
                      <SvgInline name="info-block" fillBlueBright style={{ marginRight: MARGIN.M4, maxWidth: '16px', maxHeight: '16px' }} />
                      <Text marginTop={MARGIN.M4} marginBottom={MARGIN.M4} fontSize={FONT_SIZE.SMALL} className='text-underline'>
                        Additional payout fees may apply
                      </Text>
                    </Link>
                    </>
                  )}
                </Box>
                {purpose === 'other' && (
                  <Box className="mb-4">
                    <Input
                      widget
                      id="other-purpose"
                      label="Other loan purpose"
                      maxLength="20"
                      value={purposeOther}
                      onChange={e => {
                        sessionStorage.setItem('purposeOther', e.target.value);
                        setPurposeOther(e.target.value);
                        setPurposeOtherHasChanged(true);
                      }}
                      type="text"
                      showErrorIf={() => showPurposeOtherError}
                      showWidgetError={
                        (purposeOtherHasChanged && purposeOther === '') ||
                        (!purposeOtherHasChanged && showPurposeOtherError)
                      }
                      errorMessage={showPurposeOtherErrorMessage}
                    />
                  </Box>
                )}
              </>
            )}
          </LoanPurpose>
          <BorrowAmount main={main} broker={broker}>
            <Box className="mb-4">
              <CurrencyInput
                widget
                labelText={
                  broker ? "They'd like to borrow" : "I'd like to borrow"
                }
                name="currency-input-story"
                maxLength={9}
                prefix="$"
                value={loanAmount}
                onValueChange={e => {
                  sessionStorage.setItem('loanAmount', e.value);
                  setLoanAmount(e.value);
                  const validationResult = validateLoanAmount({
                    amountValue: e.floatValue,
                    isBetterStart,
                    isBroker: broker,
                    isGem,
                    purpose
                  });
                  setHasLoanAmountError(validationResult.isError);
                  setHasLoanAmountWarning(validationResult.isWarning);
                  setAmountValidationMessage(validationResult.message);
                }}
                showErrorMessage={
                  showAmountErrorMessage || amountValidationMessage
                }
                errorStatus={
                  hasLoanAmountError ||
                  hasLoanAmountWarning ||
                  showAmountMinError ||
                  showAmountMaxError
                }
                showError={
                  hasLoanAmountError ||
                  hasLoanAmountWarning ||
                  showAmountMinError ||
                  showAmountMaxError
                }
              />
            </Box>
          </BorrowAmount>
          <RepaymentPeriod main={main} broker={broker}>
            {repaymentPeriod && (
              <Box className="mb-4">
                <Select
                  widget
                  color={COLOR.BLUE}
                  label="Over a period of"
                  selectedValue={repaymentPeriod}
                  values={isGem ? repaymentPeriodNzOptions : (isBetterStart ? repaymentPeriodBetterStartOptions : repaymentPeriodOptions)}
                  onChange={e => {
                    sessionStorage.setItem('repaymentPeriod', e.target.value);
                    setRepaymentPeriod(e.target.value);
                  }}
                />
              </Box>
            )}
            {result && (
              <Box className="mb-4">
                <Select
                  widget
                  color={COLOR.BLUE}
                  label="Making repayments every"
                  selectedValue={frequency}
                  values={frequencyOptions}
                  onChange={e => {
                    sessionStorage.setItem('frequency', e.target.value);
                    setFrequency(e.target.value);
                  }}
                />
              </Box>
            )}
          </RepaymentPeriod>
          {broker && (
            <Box className="mb-4">
              <CurrencyInput
                widget
                labelText="Brokerage fee (incl. GST)"
                name="broker-fee"
                maxLength={10}
                prefix="$"
                value={brokerFeeFieldValue}
                onValueChange={e => {
                  sessionStorage.setItem('brokerFee', e.value);
                  setBrokerFeeFieldValue(e.value);
                }}
                showErrorMessage={brokerFeeErrorMessage}
                errorStatus={
                  brokerFeeHasError
                }
                showError={
                  brokerFeeHasError
                }
              />
            </Box>
          )}
        </FormFields>

        {(main || (broker && !result)) && !quote && (
          <>
            <Link
              className="mx-auto"
              button="primary"
              width="150px"
              onClick={getRate}
              href="javascript:;"
            >
              Get started
            </Link>
            <Box marginTop={MARGIN.M16}>
              <ConfettiText
                text={
                  broker
                    ? "This won't effect your client's Credit Score"
                    : "This won't affect your Credit Score"
                }
                backgroundColor={`${COLOR.TRANSPARENT}`}
              />
            </Box>
          </>
        )}
      </MainContent>

      {(main || (broker && !result)) && !quote && (
        <Footer>
          <Text align={ALIGN.CENTER}>
            Already have a rate?{' '}
            {broker && !quote ? (
              <Link href={getSalesforceBrokerUrl()}>Retrieve rate</Link>
            ) : isGem ? (
              <Link href={SOFT_QUOTE_NZ_RETRIEVE_URL}>Retrieve your rate</Link>
            ) : (
              <Link href={SOFT_QUOTE_AU_RETRIEVE_URL}>Retrieve your rate</Link>
              )}
          </Text>
        </Footer>
      )}
      <Modal
        key="more-info-model"
        className="more-info-modal"
        isOpen={loanPurposeMoreInfoModalOpen}
        onRequestClose={()=>{setLoanPurposeMoreInfoModalOpen(false)}}
        title='Additional payout fees may apply'
        content={[
          `When applying for a debt consolidation loan, you may be charged fees by the other financier(s) when we repay your debts. Please include these fees in your requested loan amount.`,
          `When you apply for your loan, you will be asked to accept these fees in order to acheive your objectives.`
        ]}
        ariaHideApp={loanPurposeMoreInfoModalOpen}
      />
    </Container>
  );
}

export default MainRateWidget;
