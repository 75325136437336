export const CHANNEL = Object.freeze({
  AU: 'au',
  BROKER: 'broker',
  GEM: 'gem',
  KIWI: 'kiwi'
});

export const REGION = Object.freeze({
  AU: 'AU',
  NZ: 'NZ'
});

export const COUNTRY = Object.freeze({
  AU: 'Australia',
  NZ: 'New Zealand'
});

export const PRODUCT = Object.freeze({
  LATITUDE: 'Latitude Personal Loan',
  GEM: 'GEM Personal Loan',
  KIWI: 'Kiwi Personal Loan'
});

export const REQUEST_CHANNEL = Object.freeze({
  LFComAu: 'lf-com-au',
  GFCoNz: 'gf-co-nz'
});

export const RESIDENTIAL_ADDRESS_TYPE = Object.freeze({
  Current: 'Current',
  Previous: 'Previous'
});

export const GEM_MINIMUM_RESIDENCY_IN_MONTHS = 24;
export const AU_MINIMUM_RESIDENCY_IN_MONTHS = 36;

export const LOAN_PURPOSE = Object.freeze({
  AUTO_PURCHASE: 'auto-purchase',
  CARAVAN_BOAT_RECREATIONAL: 'caravan-boat-recreational',
  EDUCATION: 'education',
  HOUSEHOLD_FURNISHINGS: 'household-furnishings',
  AUTO_REPAIRS: 'auto-repairs',
  CONSOLIDATION: 'consolidation',
  HOME_IMPROVEMENTS: 'home-improvements',
  MEDICAL_DENTAL: 'medical-dental',
  TRAVEL_HOLIDAY: 'travel-holiday',
  OTHER: 'other',
});

export const LOAN_PURPOSE_AU = Object.freeze({
  NOT_SELECTED: 'purpose',
  CAR_PURCHASE: 'car-purchase',
  CAR_REPAIRS: 'car-repairs',
  DEBT_CONSOLIDATION: 'debt-consolidation',
  EDUCATIONAL_EXPENSES: 'educational-expenses',
  HOME_IMPROVEMENTS: 'home-improvements',
  HOUSEHOLD_FURNISHINGS: 'household-furnishings',
  MEDICAL_DENTAL: 'medical-dental',
  OTHER_VEHICLE_PURCHASE: 'other-vehicle-purchase',
  SMALL_DEBTS: 'small-debts',
  TRAVEL : 'travel',
  OTHER : 'other',
});

export const SALESFORCE_STATUS = Object.freeze({
  InitialSubmit: 'Initial Submit',
  QuoteOffered: 'Quote Offered',
  Referred: 'Referred',
  Declined: 'Declined',
  ServiceUnavailable: 'Service Unavailable'
});

export const PAGE_OUTCOME_STATUS = Object.freeze({
  Quoted: 'Quoted',
  Referred: 'Referred',
  Declined: 'Declined',
  ServiceUnavailable: 'Service Unavailable'
});

export const SALESFORCE_ANALYTICS_OUTCOME_STATUS_MAP = new Map([
    [SALESFORCE_STATUS.InitialSubmit, PAGE_OUTCOME_STATUS.Referred],
    [SALESFORCE_STATUS.QuoteOffered, PAGE_OUTCOME_STATUS.Quoted],
    [SALESFORCE_STATUS.Referred, PAGE_OUTCOME_STATUS.Referred],
    [SALESFORCE_STATUS.Declined, PAGE_OUTCOME_STATUS.Declined],
    [SALESFORCE_STATUS.ServiceUnavailable, PAGE_OUTCOME_STATUS.ServiceUnavailable],
  ]
);
export const SALESFORCE_RETRIEVE_STATUS = Object.freeze({
  ConvertedToApplication: 'Converted to Application',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  EMAIL_ID_BLANK: 'EMAIL_ID_BLANK',
  INVALID_DOB_FORMAT: 'INVALID_DOB_FORMAT',
  MAX_SMS_ATTEMPTS_REACHED: 'MAX_SMS_ATTEMPTS_REACHED',
  NO_ACTIVE_QUOTE: 'NO_ACTIVE_QUOTE'
});

export const SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS = Object.freeze({
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  OTP_EXPIRED: 'OTP_EXPIRED',
  QUOTE_ID_BLANK: 'QUOTE_ID_BLANK',
  TRANSACTION_ID_BLANK: 'TRANSACTION_ID_BLANK',
  OTP_BLANK: 'OTP_BLANK',
  INCORRECT_DETAILS: 'INCORRECT_DETAILS',
  OTP_ALREADY_VERIFIED: 'OTP_ALREADY_VERIFIED',
  OTP_VERIFICATION_MAX_ATTEMPTS: 'OTP_VERIFICATION_MAX_ATTEMPTS',
  OTP_INCORRECT: 'OTP_INCORRECT',
  ERROR_SENDING_SMS: 'ERROR_SENDING_SMS'
});

export const RETRIEVE_USER_STATUS = Object.freeze({
  approved: 'approved',
  retrieve: 'retrieve',
  verify: 'verify',
  expired: 'expired',
  server_error: 'server-error',
  proceed: 'proceed'
});
