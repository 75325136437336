// @flow

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  INPUT_PADDING,
  FONT_SIZE,
  LINE_HEIGHT,
  COLOR,
  POSITION,
  FONT_FAMILY
} from '../../utils/constants';
import { Box } from '../Box/Box';

type Props = {
  /** Error function that determines whether or not an error should be displayed */
  showErrorIf?: () => void,

  /** onChange function handler */
  onChange: () => void,

  /** Error message to be displayed on error */
  errorMessage?: string,

  /** Optional prefix to display in the input i.e. $ */
  prefix?: string,

  /** Placeholder string for the input */
  placeholder?: string,

  /** Label string for the input field */
  label?: string,

  /** HTML name attribue for the input */
  name?: string,

  /** The input value */
  value: string | number,

  /** Specify a unique id for the HTML element. */
  id: string,

  /** The minimum value for the input, to be used if input type is number */
  min?: number,

  /** The maximum value for the input, to be used if input type is number */
  max?: number,

  /** The step value for the input, to be used if input type is number */
  step?: number,

  /** The HTML input attribute type */
  type: string,

  /** The maximum length of the input */
  maxLength?: number,

  /** Override the default color of the input */
  color?: $Values<typeof COLOR>,

  /** Applies Widget styling */
  widget?: boolean
};

type StyledProps = Props & {
  cssColor?: $Values<typeof COLOR>,
  hasPrefix?: boolean
};

const StyledInput = styled.input`
  padding: ${INPUT_PADDING.NORMAL};
  padding-left: ${({ hasPrefix }: StyledProps) => hasPrefix && '1.75em'};
  font-size: ${FONT_SIZE.NORMAL};
  line-height: ${LINE_HEIGHT.STANDARD.NORMAL};
  color: ${({ cssColor, hasError }: StyledProps) =>
    hasError ? COLOR.ERROR : cssColor};
  appearance: none;
  border: none;
  border-radius: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  outline: none;
  color: ${({ widget, cssColor }: StyledProps) =>
    widget ? COLOR.BLUE : cssColor};
  background: ${({ widget }: StyledProps) =>
    widget ? 'none' : `${COLOR.WHITE}`};
  border-bottom: ${({ widget, hasError }: StyledProps) =>
    widget ? hasError ? `4px solid ${COLOR.TEXT_ERROR} !important` : `1px solid ${COLOR.BLUE}` : 'none'};
  font-family: ${({ widget }: StyledProps) =>
    widget ? `${FONT_FAMILY.TITLE}` : `${FONT_FAMILY.BODY}`};
  font-size: ${({ widget }: StyledProps) =>
    widget ? `20px` : `${FONT_SIZE.NORMAL}`};
  padding: ${({ widget }: StyledProps) =>
    widget ? `${INPUT_PADDING.WIDGET}` : `${INPUT_PADDING.NORMAL}`};

  ${({ widget }: StyledProps) =>
    widget &&
    css`
      font-weight: 600;
    `}
`;

const StyledLabel = styled.label`
  ${({ widget }: StyledProps) =>
    widget &&
    css`
      margin-bottom: 0;
      font-size: ${FONT_SIZE.LARGE};
      font-weight: 300;
      text-align: left;
    `}
`;

const StyledPrepend = styled.span`
  color: ${({ cssColor, hasError }: StyledProps) =>
    hasError ? COLOR.ERROR : cssColor};
  font-size: ${FONT_SIZE.NORMAL};
  line-height: ${LINE_HEIGHT.STANDARD.NORMAL};
  display: block;
  position: absolute;
  width: 1.25em;
  text-align: right;
  left: 0;
  padding: 0.75em 0;
`;

const StyledError = styled.span`
  display: ${({ widget }: StyledProps) =>
    widget ? 'flex' : ''};
  color: ${({ widget }: StyledProps) =>
    widget ? COLOR.TEXT_ERROR: COLOR.ERROR};
`;

const Input = (props: Props) => {
  const {
    showErrorIf,
    onChange,
    errorMessage,
    prefix,
    placeholder,
    label,
    name,
    value,
    id,
    min,
    max,
    step,
    type,
    maxLength,
    color = COLOR.BODY,
    widget,
    showWidgetError
  } = props;
  const [showError, setShowError] = useState(false);

  return (
    <Box position={POSITION.RELATIVE}>
      {label && (
        <StyledLabel id={`${id}-label`} htmlFor={id} widget={widget}>
          {label}
        </StyledLabel>
      )}
      {prefix && (
        <StyledPrepend cssColor={color} hasError={showError} style={{}}>
          {prefix}
        </StyledPrepend>
      )}
      <StyledInput
        id={id}
        cssColor={color}
        type={type || 'text'}
        onBlur={() => setShowError(showErrorIf && showErrorIf())}
        hasPrefix={prefix}
        hasError={showError || showWidgetError}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        step={step}
        maxLength={maxLength}
        widget={widget}
      />
      {(showError || showWidgetError) && <StyledError widget={widget}>{errorMessage}</StyledError>}
    </Box>
  );
};

export default Input;
