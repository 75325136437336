// @flow

import React, { useState } from 'react';
import { Analytics, AnalyticsContext, trackEvent } from 'latitude-analytics';
import { Text } from '@latitude/text';
import NumberFormat, {
  NumberFormatValues,
  SyntheticInputEvent
} from 'react-number-format';
import { CALCULATOR_TRACKING } from '../../utils/constants';
import './_currency-input.scss';

const component = 'currency-input';

type Props = {
  /**  Name of input */
  name: string,
  /**  Classes */
  className?: string,
  /**  Label of input, displayed above */
  labelText?: string,
  /**  Currency prefix, displayed inside input to left of value */
  prefix?: string,
  /**  Value of input, to be controlled by parent */
  value?: number | string,
  /**  Input placeholder value */
  placeholder?: string,
  /**  onValueChange handler, for 'react-number-format' */
  onValueChange?: (values: NumberFormatValues, e: SyntheticInputEvent) => void,
  /**  onChange handler, called when input value changes */
  onChange?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  /**  If to show error message */
  showError?: boolean,
  /**  Error message that should be displayed when showError is true */
  showErrorMessage?: string,
  /**  Maximum length of the input */
  maxLength?: number,
  /**  If input allows negative number values */
  allowNegative?: boolean,
  /**  Optional input type attribute */
  type?: string,
  /** Remove the default text styling. */
  widget?: boolean,
  /** Optional event tracking */
  disableDefaultEventTracking?: boolean
};

const CurrencyInput = (props: Props) => {
  const {
    name,
    className,
    labelText,
    value,
    placeholder,
    showErrorMessage,
    showError,
    onValueChange,
    onChange,
    maxLength,
    prefix,
    allowNegative = true,
    type,
    widget = false,
    disableDefaultEventTracking = false,
    kiwibank=false
  } = props;

  /**
   * Used to store previous amount value, which then allows us
   * to trigger Tealium tracking only when this use case is applicable
   */
  const [amount, setAmount] = useState(value);

  // Update styling props values
  const extraClasses = showError ? ' error' : '';
  const labelStyle = showError ? `${component}__label-error` : '';
  const widgetClass = widget ? `${component}--widget` : '';
  const kiwibankClass = kiwibank ? `${component}--kiwibank` : '';

  return (
    <AnalyticsContext.Consumer>
      {([analytics]: [Analytics]) => (
        <div
          className={`${component} input-wrap ${className ||
            ''} ${widgetClass}`}
        >
          <div className={labelStyle}>
            {/* eslint-disable-next-line */}
            <Text>{labelText && <label>{labelText}</label>}</Text>
          </div>

          <div className="pos-rel">
            {prefix && (
              <span className={`${component}__prepend ${kiwibankClass}`}>{prefix}</span>
            )}

            <NumberFormat
              className={`${component}__field styled-input-wrap__input dollar ${extraClasses} ${kiwibankClass} ${widgetClass}`}
              name={name}
              thousandSeparator
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              decimalScale={0}
              allowNegative={allowNegative}
              onValueChange={onValueChange}
              onChange={onChange}
              onBlur={(event: SyntheticEvent<HTMLInputElement>) => {
                setAmount(value);
                if (disableDefaultEventTracking !== true) {
                  if (value !== amount) {
                    if (trackEvent) {
                      trackEvent(analytics, {
                        category: CALCULATOR_TRACKING.CATEGORY,
                        action: CALCULATOR_TRACKING.ACTION,
                        label: labelText,
                        location: CALCULATOR_TRACKING.LOCATION,
                        value: event.currentTarget?.value === '' ? '0' : event.currentTarget?.value
                      });
                    }
                  }
                }
              }}
              type={type}
              inputMode="decimal"
            />
            {showError && (
              <div className={`${component}__error-container`}>
                <div className={`${component}__error-message`}>
                  {showErrorMessage}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </AnalyticsContext.Consumer>
  );
};

export default CurrencyInput;
