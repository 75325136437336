import { formatAsCurrency } from '../../../utils';


export const validateBrokerageFee = ({ loanAmount, brokerageFee }) => {
  const percentage = 0.15;
  let isValid = false;
  let message;

  brokerCommissionRanges.map(brokerCommissionRange => {
    if (loanAmount >= brokerCommissionRange.min && loanAmount <= brokerCommissionRange.max) {
      isValid = (brokerageFee <= (loanAmount * percentage) && (brokerageFee <= brokerCommissionRange.capped));

      if (!isValid && (brokerageFee > (loanAmount * percentage))) {
        message = `Brokerage fee capped at 15% of the loan amount (${formatAsCurrency(loanAmount * percentage)})`;
      }
      if (!isValid && brokerageFee > brokerCommissionRange.capped) {
        message = `Brokerage fee must be ${formatAsCurrency(brokerCommissionRange.capped)} or less (capped at 15% of loan amount) for loans between ${formatAsCurrency(brokerCommissionRange.min)} and ${formatAsCurrency(brokerCommissionRange.max)}`;
      }
    }

  });
  return ({isValid, message});
}

const brokerCommissionRanges = [
  {min: 4000, max: 20000, capped: 990},
  {min: 20001, max: 100000, capped: 1650},
]
