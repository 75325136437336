// @flow

import React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import {
  COLOR,
  FONT_FAMILY,
  FONT_SIZE,
  LINE_HEIGHT,
  INPUT_PADDING
} from '../../utils/constants';

type Values = {
  /** The actual value of the select field */
  value: string | number,

  /** The text value of the select field to be displayed */
  text: string
};

type Props = {
  /** onChange function handler */
  onChange: () => void,

  /** onBlur function handler */
  onBlur: () => void,

  /** Placeholder string for the input */
  placeholder?: string,

  /** Label string for the input field */
  label?: string,

  /** HTML name attribue for the input */
  name?: string,

  /** The currently selected value */
  selectedValue: string | number,

  /** The values to be displayed in the select field */
  values: Array<Values>,

  /** Specify a unique id for the HTML element. */
  id: string,

  /** Extra CSS classes */
  className?: string,

  /**  If to show error message */
  showError?: boolean,

  /**  Error message that should be displayed when showError is true */
  showErrorMessage?: string,

  /** Override the default color of the input */
  color?: $Values<typeof COLOR>,

  /** Applies widget specific styles */
  widget?: boolean,

  /** allow use of disabled selects */
  disabled?: boolean
};

type StyledProps = Props & {
  cssColor?: $Values<typeof COLOR>
};

const StyledSelect = styled.select`
  appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  border: ${({ showError, widget }: StyledProps) =>
    !widget && showError ? `1px solid ${COLOR.TEXT_ERROR}` : 'none'};
  border-radius: 0;
  ${({ disabled }: StyleProps) => disabled ? '':'cursor:pointer;'}
  background: ${({ widget }: StyledProps) =>
    widget ? 'none' : `${COLOR.WHITE}`};
  border-bottom: ${({ widget, showError }: StyledProps) =>
    widget ? (showError ? `4px solid ${COLOR.TEXT_ERROR}` : `1px solid ${COLOR.BLUE}`) : 'none'};
  outline: none;
  font-family: ${({ widget }: StyledProps) =>
    widget ? `${FONT_FAMILY.TITLE}` : `${FONT_FAMILY.BODY}`};
  color: ${({ cssColor }: StyledProps) => cssColor};
  font-size: ${({ widget }: StyledProps) =>
    widget ? `20px` : `${FONT_SIZE.NORMAL}`};
  line-height: ${LINE_HEIGHT.STANDARD.NORMAL};
  padding: ${({ widget }: StyledProps) =>
    widget ? `${INPUT_PADDING.WIDGET}` : `${INPUT_PADDING.NORMAL}`};
  max-width: 100%;
  background-image: url('/media/forms/chevron-grey.svg');
  background-size: 10px 5px;
  background-position: center right 10px;
  background-repeat: no-repeat;
  opacity:1;

  ${({ widget, disabled }: StyledProps) =>
    widget &&
    css`
      padding-right: 32px;
      background-image: ${disabled?null:`url(${require('./images/chevron.svg')});`}
      background-position: center right 0;
      background-repeat: no-repeat;
      background-size: unset;
      font-weight: 600;
    `}

  &::-ms-expand {
    display: none;
  }
`;

const StyledErrorContainer = styled.div`
  position: relative;
  padding: 10px 0 0 0;
  text-align: ${({ widget }: StyledProps) =>
    widget ? 'left !important' : ''};
`;

const StyledErrorMessage = styled.div`
  color: ${COLOR.TEXT_ERROR};
`;

const StyledLabel = styled.label`
  color: ${({ showError }: StyledProps) =>
    showError ? COLOR.TEXT_ERROR : 'inherit'};

  ${({ widget }: StyledProps) =>
    widget &&
    css`
      margin-bottom: 0;
      font-size: ${FONT_SIZE.LARGE};
      font-weight: 300;
      text-align: left;
    `}
`;

const Select = (props: Props) => {
  const {
    id,
    label,
    placeholder,
    onChange,
    onBlur,
    name,
    selectedValue,
    values,
    className,
    showError,
    showErrorMessage,
    widget,
    disabled = false,
    color = COLOR.BODY
  } = props;

  var placeholderOption = '';
  if (placeholder) {
    placeholderOption = (
      <option value="" key={placeholder} disabled defaultValue>
        {placeholder}
      </option>
    );
  }
  return (
    <>
      {label && (
        <StyledLabel
          id={`${id}-label`}
          htmlFor={id}
          showError={!widget && showError}
          widget={widget}
        >
          {label}
        </StyledLabel>
      )}
      <StyledSelect
        id={id}
        disabled={disabled}
        cssColor={color}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={selectedValue}
        placeholder={placeholder}
        showError={showError}
        className={classnames('styled-select--wide', className)}
        widget={widget}
      >
        {placeholderOption}
        {values.map((v: Values) => (
          <option value={v.value} key={v.text + '' + v.value}>
            {v.text}
          </option>
        ))}
      </StyledSelect>
      {showError && (
        <StyledErrorContainer widget={widget}>
          <StyledErrorMessage>{showErrorMessage}</StyledErrorMessage>
        </StyledErrorContainer>
      )}
    </>
  );
};

export default Select;
