import {validateBrokerageFee} from "@/components/PersonalLoanSoftQuote/utils/validateBrokerageFee";

const BROKER_ID_REGEX = /^[Bb]-[0-9]+$/
const BROKER_FEE_REGEX = /^[0-9.]/
export function validateBrokerId() {
  return value => {
    const brokerIdLength = value.trim().length;
    if (brokerIdLength === 0 ) {
      return 'Enter a broker number';
    }
    if (brokerIdLength > 10 || !BROKER_ID_REGEX.test(value)) {
      return 'Enter a valid broker number';
    }
    return null;
  };
}

export function validateBrokerFee({brokerFeeFieldValue, loanAmount}) {
  let returnValue = {
    isError: true,
    brokerFeeFieldValue,
  };
  if ( typeof(brokerFeeFieldValue) !== 'string') {
    returnValue.message = 'Please enter a valid brokerage fee';
    returnValue.brokerFeeFieldValue = '';
    return returnValue;
  }
  const brokerFeeLength = brokerFeeFieldValue.trim().length;
  if (brokerFeeLength === 0) {
    returnValue.message = 'Enter a Brokerage fee';
    return returnValue;
  }
  const brokerFeeParsed = parseFloat(brokerFeeFieldValue);

  if (isNaN(brokerFeeParsed) || !BROKER_FEE_REGEX.test(brokerFeeFieldValue)) {
    returnValue.message = 'Enter a valid Brokerage fee';
    return returnValue;
  }

  const validatedBroker = validateBrokerageFee({loanAmount, brokerageFee: brokerFeeParsed })
  return {isError: !validatedBroker.isValid, message: validatedBroker.message, brokerFee: brokerFeeParsed}
}

export const brokerIdValidation = validateBrokerId();
export const brokerIdServerCheckError = 'Invalid broker number. Please try again.';
