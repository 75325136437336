// @flow

import React from 'react';
import styled from 'styled-components';
import { Heading3, Heading6 } from '@latitude/heading';
import { Box } from '../Box/Box';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  POSITION
} from '../../utils/constants';

type Props = {
  /** Text for the header */
  text: string,
  /** Use Heading3 if true else Heading6 */
  header?: boolean,
  /** Sets background color of header */
  backgroundColor?: string,
  /** Sets text background color of header */
  textBackgroundColor?: string,
  /** Sets the size of the image. Could be Large */
  size?: string,
  /** Sets the width of the image */
  imageWidth?: string,
  /** Sets the height of the image */
  imageHeight?: string,
  /** Sets the top position of the image */
  imageTop?: string
};

const SmallSvg = require(`./svg/confetti-stamps-small.svg`);
const LargeSvg = require(`./svg/confetti-stamps-large.svg`);
const LeftLargeSvg = require(`./svg/confetti-stamps-left-large.svg`);

//TODO: Incorporate styles for pink bg (in softquote) and left confetti (results page)
const HeadingContainer = styled(Box)`
  text-align: center;
  h6 {
    position: ${POSITION.RELATIVE};
    display: inline-block;
    text-align: ${(props: Props) => props.textAlign ?? 'center'};
    background-color: ${(props: Props) =>
      props.textBackgroundColor ?? COLOR.TRANSPARENT};
    ${(props: Props) =>
      props.size == 'Large'
        ? `
      padding: 8px 20px;
      @media (max-width: '320px') {
        margin-left: -20px;
        padding: 8px 4px;
        font-size: 14px;
      }
      @media (max-width: ${BREAKPOINT.XS}) {
        padding: 8px 16px;
        margin-left: -20px;
        font-size: 14px;
      }`
        : ''};
  }
  h6:after {
    content: '';
    background: url(${(props: Props) =>
        props.size == 'Large' ? LargeSvg : SmallSvg})
      top right no-repeat;
    position: ${POSITION.ABSOLUTE};
    width: ${(props: Props) => props.imageWidth ?? '40px'};
    height: ${(props: Props) => props.imageHeight ?? '34px'};
    top: ${(props: Props) => props.imageTop ?? '-14px'};
    right: ${(props: Props) => props.imageTop ?? '-20px'};
  }

  h3 {
    position: ${POSITION.RELATIVE};
    display: inline;
    text-align: ${(props: Props) => props.textAlign ?? 'center'};
    margin: 0px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    -webkit-letter-spacing: -0.7px;
    -moz-letter-spacing: -0.7px;
    -ms-letter-spacing: -0.7px;
    letter-spacing: -0.7px;
    color: ${COLOR.BLACK};
    text-align: ${ALIGN.CENTER};
    @media (max-width: ${BREAKPOINT.XS}) {
      max-width: 279px;
    }
    @media (max-width: 320px) {
      max-width: 250px;
    }
  }

  h3:before {
    content: '';
    background: url(${LeftLargeSvg}) top left no-repeat;
    position: ${POSITION.ABSOLUTE};
    width: 70px;
    height: 56px;
    top: -26px;
    left: -35px;
  }
`;

const ConfettiText = (props: Props) => {
  const {
    text,
    header,
    backgroundColor,
    size,
    imageWidth,
    imageHeight,
    imageTop,
    textBackgroundColor
  } = props;

  return (
    <HeadingContainer
      backgroundColor={backgroundColor}
      size={size}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      imageTop={imageTop}
      textBackgroundColor={textBackgroundColor}
    >
      {header && <Heading3>{text}</Heading3>}
      {!header && (
        <Heading6
          marginBottom={`${MARGIN.M8}`}
          marginTop={`${MARGIN.M8}`}
          color={COLOR.BLACK}
        >
          {text}
        </Heading6>
      )}
    </HeadingContainer>
  );
};

export default ConfettiText;
